<!-- emailPreviewHtml -->
<template>
  <div class="emailPreviewHtml">
    <el-tabs type="border-card" v-model="checkTab" v-if="tabData.length > 0">
      <el-tab-pane
        v-for="(item, index) in tabData"
        :key="index"
        :label="item.text"
        :name="String(item.id)"
      ></el-tab-pane>
    </el-tabs>
    <iframe
      id="iframe"
      style="width: 820px; margin-top: 20px"
      :src="pageUrl"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { Get } from 'kjqk-commons/src/common/index.js'
export default {
  name: 'EmailPreviewHtml',
  data() {
    return {
      tabData: [],
      height: '',
      checkTab: ''
    }
  },
  props: {},
  components: {},
  computed: {
    pageUrl() {
      return (
        window.location.origin +
        '/das-api/vas/push/task/getTemplateHtml5InfoAndType?historyId=' +
        this.$route.query.taskId +
        '&pushHistoryTemplateId=' +
        this.$route.query.pushHistoryTemplateId +
        '&type=' +
        this.checkTab +
        '&nameSpace=' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '') +
        '/das-api'
      )
    }
  },
  mounted() {
    this.getTemplateInfoLabel()
    this.setIframeHeight('iframe')
  },
  methods: {
    setIframeHeight(id) {
      try {
        var iframe = document.getElementById(id)
        if (iframe.attachEvent) {
          iframe.attachEvent('onload', function () {
            iframe.height =
              iframe.contentWindow.document.documentElement.scrollHeight + 20
          })
          return
        } else {
          iframe.onload = function () {
            iframe.height = iframe.contentDocument.body.scrollHeight + 20
          }
          return
        }
      } catch (e) {
        throw new Error('setIframeHeight Error')
      }
    },
    getTemplateInfoLabel() {
      let params = {
        historyId: this.$route.query.taskId,
        pushHistoryTemplateId: this.$route.query.pushHistoryTemplateId
      }
      let url = '/das-api/vas/push/task/getTemplateInfoLabel'
      Get(url, { params }).then((res) => {
        this.tabData = []
        if (res.data.status === 0) {
          let data = res.data.data
          for (let i in data) {
            let obj = {
              text: i,
              id: data[i]
            }
            this.tabData.push(obj)
          }
          if (this.tabData.length > 0) {
            this.checkTab = String(this.tabData[0].id)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.emailPreviewHtml {
  text-align: center;
  .el-tabs {
    width: 810px;
    margin: auto;
    .el-tabs__content {
      display: none;
    }
  }
}
</style>
